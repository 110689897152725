@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Lato;
}

body {
  @apply text-text;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
