@import url('https://cdn.syncfusion.com/ej2/material.css');

@import '../node_modules/@syncfusion/ej2-react-kanban/styles/material.css';

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

.linear-gradient {
  /* background: rgb(254, 254, 254); */
  background: linear-gradient(180deg, rgba(254, 254, 254, 1) 0%, rgba(218, 218, 218, 1) 100%);
  box-shadow: 0px 4px 14px 14px rgba(0, 0, 0, 0.01);
}
.red-gradient {
  background: linear-gradient(180deg, #e65f5f 0%, #e10000 100%);
}

.black-gradient {
  background: linear-gradient(180deg, #3a3a3a 0%, #000000 100%);
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.border-radius {
  border-radius: 14px;
}

/*-------------------------
---------------------------
      table styles 
---------------------------
---------------------------*/

/* Table Headers */
.e-headertext {
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
  color: #16213e;
}

/* row font styles */
.e-rowcell {
  font-family: Lato;
  font-weight: 300;
  color: #16213e !important;
  font-size: 14px !important;
}
/* row background color */
.e-grid .e-row {
  background-color: #f7f7f7;
}

/* Table Header Background */
th.e-headercell {
  background-color: #f7f7f7 !important;
}

/* Select styles */
span.e-frame {
  background: linear-gradient(180deg, #fdfdfd 0%, #dbdbdb 100%);
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
  position: relative !important;
}
span.e-frame::before {
  color: #16213e90 !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* grid bottom */
.e-grid {
  border-radius: 10px;
  overflow: hidden !important;
  border: 1px solid #00000020 !important;
  /* border-top: none !important; */
}
.e-grid .e-gridheader {
  border-top: none;
  border-top: 1px solid #00000020;
  border-bottom: 1px solid #00000020;
  /* border-top-left-radius: 12px;
  border-top-right-radius: 12px; */
}
.e-gridcontent {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

/* Toolbar */
.e-toolbar,
.e-toolbar-items,
.e-toolbar-item,
.e-tbar-btn {
  background-color: #f7f7f7 !important;
}
.e-toolbar {
  border-top: 1px solid #00000020;
}
.e-tbar-btn-text {
  color: #16213e !important;
  font-weight: 500;
}
.e-btn-icon.e-icons {
  color: #16213e !important;
}

.e-groupdroparea {
  background-color: #efefef !important;
  border-top: none !important;
  color: #16213e !important;
  font-weight: 400 !important;
}
.e-groupdroparea.e-focused {
  outline: 1px solid green !important;
}

.e-edit-dialog .e-dlg-header-content,
.e-edit-dialog .e-dlg-content,
.e-edit-dialog .e-footer-content {
  background-color: #f7f7f7;
}

.e-edit-dialog .e-dlg-modal {
  /* background-color: #f7f7f7 !important; */
  border-radius: 20px !important;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #ba0202;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #ee0000;
  border-left: 4px solid transparent;
}

::-webkit-scrollbar {
  width: 4px;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  @apply bg-fade;
  border-radius: 8px;
}
::-webkit-scrollbar-track {
  @apply bg-transparent;
}
::-webkit-scrollbar-thumb:hover {
  @apply bg-slate-800;
}

@supports (scrollbar-color: red blue) {
  * {
    scrollbar-color: #16c79a transparent;
    scrollbar-width: thin;
  }
  .no-scrollbar {
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
